<template>
  <van-dropdown-menu>

    <van-dropdown-item ref="item1" :title="title1">
      <div class="dropdown-fill">
        <div class="dropdown-content">
          <check-button-group v-model="value1">
            <div class="area-title">A区</div>
            <van-grid :column-num="4" :border="false">
              <van-grid-item v-for="(area, index) in schoolProvince.areaA" :key="index">
                <check-button :name="area" :label="area" class="check-button"></check-button>
              </van-grid-item>
            </van-grid>
            <div class="area-title">B区</div>
            <van-grid :column-num="4" :border="false">
              <van-grid-item v-for="(area, index) in schoolProvince.areaB" :key="index">
                <check-button :name="area" :label="area" class="check-button"></check-button>
              </van-grid-item>
            </van-grid>
          </check-button-group>
        </div>
        <div class="dropdown-buttons">
          <van-button class="button" size="normal" type="danger" round plain @click="onReset1">重置</van-button>
          <van-button class="button" size="normal" type="danger" round @click="onSubmit1">确定</van-button>
        </div>
      </div>
    </van-dropdown-item>

    <van-dropdown-item ref="item2" :title="title2">
      <div class="dropdown-fill">
        <div class="dropdown-content">
          <check-button-group v-model="value2">
            <van-grid :column-num="4" :border="false">
              <van-grid-item v-for="(area, index) in schoolType" :key="index">
                <check-button :name="area" :label="area" class="check-button"></check-button>
              </van-grid-item>
            </van-grid>
          </check-button-group>
        </div>
        <div class="dropdown-buttons">
          <van-button class="button" size="normal" type="danger" round plain @click="onReset2">重置</van-button>
          <van-button class="button" size="normal" type="danger" round @click="onSubmit2">确定</van-button>
        </div>
      </div>
    </van-dropdown-item>

    <van-dropdown-item ref="item3" :title="title3">
      <div class="dropdown-fill">
        <div class="dropdown-content">
          <check-button-group v-model="value3">
            <van-grid :column-num="4" :border="false">
              <van-grid-item v-for="(area, index) in schoolCharacteristic" :key="index">
                <check-button :name="area" :label="area" class="check-button"></check-button>
              </van-grid-item>
            </van-grid>
          </check-button-group>
        </div>
        <div class="dropdown-buttons">
          <van-button class="button" size="normal" type="danger" round plain @click="onReset3">重置</van-button>
          <van-button class="button" size="normal" type="danger" round @click="onSubmit3">确定</van-button>
        </div>
      </div>
    </van-dropdown-item>

    <van-dropdown-item ref="item4" :title="title4">
      <div class="dropdown-fill search-box">
        <van-search v-model="value4" shape="round" :clearable="true" placeholder="请输入考试科目" @search="onSearch"></van-search>
      </div>
    </van-dropdown-item>

  </van-dropdown-menu>
</template>

<script>
import CheckButton from "@/components/MT/CheckButton";
import CheckButtonGroup from "@/components/MT/CheckButtonGroup";
import RadioItem from "@/components/MT/RadioItem";
import RadioItemGroup from "@/components/MT/RadioItemGroup";
import Config from "@/api/service";
export default {
  name: "SchoolFilter",
  components: {
    CheckButton,
    CheckButtonGroup,
    RadioItem,
    RadioItemGroup,
  },
  props: {
    filter1: {
      type: Array,
      default: [],
    },
    filter2: {
      type: Array,
      default: [],
    },
    filter3: {
      type: Array,
      default: [],
    },
    filter4: {
      type: String,
      default: '',
    },
  },
  computed: {
    title1() {
      if (this.value1.length > 0) {
        return this.value1.join(',');
      } else {
        return "院校位置";
      }
    },
    title2() {
      if (this.value2.length > 0) {
        return this.value2.join(',');
      } else {
        return "院校类型";
      }
    },
    title3() {
      if (this.value3.length > 0) {
        return this.value3.join(',');
      } else {
        return "院校特色";
      }
    },
    title4() {
      if (this.value4) {
        return this.value4;
      } else {
        return "考试科目";
      }
    },
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
    value4: {
      get: function () {
        return this.filter4;
      },
      set: function (val) {
        this.$emit('update:filter4', val);
      }
    },
  },
  data() {
    return {
      activeTab: 0,
      schoolProvince: {
        areaA: ["北京", "天津", "河北", "山西", "辽宁", "吉林", "黑龙江", "上海", "江苏", "浙江", "安徽", "福建", "江西", "山东", "河南", "湖北", "湖南", "广东", "重庆", "四川", "陕西"],
        areaB: ["内蒙古", "广西", "海南", "贵州", "云南", "西藏", "甘肃", "青海", "宁夏", "新疆"],
      },
      schoolType: ["综合类", "理工类", "农林类", "医药类", "师范类", "语言类", "财经类", "政法类", "体育类", "艺术类", "民族类", "军事类", "其他"],
      schoolCharacteristic: ["985", "211", "双一流", "自划线", "高等院校", "科研院所"],
    };
  },
  methods: {
    onReset1() {
      this.value1 = [];
    },
    onReset2() {
      this.value2 = [];
    },
    onReset3() {
      this.value3 = [];
    },
    onSubmit1() {
      this.$refs.item1.toggle(false);
      this.$emit("filter");
    },
    onSubmit2() {
      this.$refs.item2.toggle(false);
      this.$emit("filter");
    },
    onSubmit3() {
      this.$refs.item3.toggle(false);
      this.$emit("filter");
    },
    onSearch() {
      this.$refs.item4.toggle(false);
      this.$emit("filter");
    }
  },
  mounted() {

  }
};
</script>

<style lang="less" scoped>
.dropdown-fill {
  flex: 1;

  .dropdown-content {
    display: flex;
    overflow-y: auto;
    height: calc(100% - 60px);
  }

  .dropdown-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      width: 150px;
      margin: 10px;
    }
  }
}

.search-box {
  width: 100%;
}

.area-title {
  font-size: 16px;
  margin: 10px 20px;
}

.check-button {
  width: 80px;
  height: 35px;
}

.sidebar-left {
  width: 30%;
  background-color: #F6F6F6;
  overflow-y: auto;
}

.sidebar-right {
  width: 70%;
  background-color: white;
  overflow-y: auto;
}

::v-deep .van-dropdown-item__content {
  display: flex;
}

::v-deep .van-grid-item__content {
  padding: 5px;
}

::v-deep .van-dropdown-menu__bar {
  background-color: #F6F6F6;
  box-shadow: unset;
}
</style>
