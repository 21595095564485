<template>
    <div>
        <van-sticky>
            <title-bar2 title="查院校" />
            <title-search :value.sync="keyword" placeholder="请输入搜索院校" @search="onSearch" />
            <school-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" @filter="onFilter"></school-filter>
        </van-sticky>
        <div class="school-total">院校（{{ total }}所）</div>
        <van-list class="school-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <school-item class="school-item" v-for="school in schools" :key="school.UniversityGuid" :title="school.UniversityName" :province="school.UniversityProvince" :partition="school.UniversityPartition" :teachtype="school.UniversityTeachType" :characteristic="school.UniversityCharacteristic" :img="schoolImg(school.UniversityCode)" @click="onItemClick(school)"></school-item>
        </van-list>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import TitleSearch from "@/components/MT/TitleSearch";
import SchoolFilter from "@/components/MT/SchoolFilter";
import SchoolItem from "@/components/MT/SchoolItem";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
        TitleSearch,
        SchoolFilter,
        SchoolItem,
    },
    data() {
        return {
            keyword: null,
            index: 0,
            total: 0,
            loading: false,
            finished: false,
            filter1: [],
            filter2: [],
            filter3: [],
            filter4: '',
            schools: [],
        };
    },
    computed: {
        schoolImg() {
            return function (code) {
                return `${Config.school.logo}?SchoolCode=${code}`;
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //点击搜索
        onSearch() {
            this.filter1 = [];
            this.filter2 = [];
            this.filter3 = [];
            this.filter4 = '';
            this.onReload();
        },
        //点击过滤
        onFilter() {
            this.keyword = '';
            this.onReload();
        },
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.schools = [];
            this.onLoad();
        },
        //加载当前页数据
        onLoad() {
            var model = {
                SearchKeyword: this.keyword,
                UniversityProvinces: this.filter1,
                UniversityTeachTypes: this.filter2,
                UniversityCharacteristics: this.filter3,
                UniversitySubject: this.filter4,
                PageIndex: ++this.index,
                PageSize: 20,
            };
            this.$axios
                .post(Config.school.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.schools.push(...data.UniversityInfos);
                    this.loading = false;
                    this.total = data.TotalCount;
                    if (this.index >= data.PageCount) {
                        this.finished = true;
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //点击大学项
        onItemClick(university) {
            this.$router.push({
                name: "School1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    universityCode: university.UniversityCode,
                }
            });
        },
    },
    mounted() {
        this.getRoutParams();
        this.onLoad();
    }
};
</script>
<style scoped>
.school-total {
    margin-left: 10px;
    color: gray;
    font-size: 14px;
}

.school-list {
    margin-top: 10px;
}

.school-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
</style>